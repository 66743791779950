/* eslint-disable no-unused-vars */
import DataSource from 'devextreme/data/data_source'
import CustomStore from 'devextreme/data/custom_store'
import inventoryService from '@/http/requests/inventory/inventoryService'
import Pager from '@core/dev-extreme/utils/pager'

const store = new CustomStore({
  key: 'id',
  cacheRawData: false,
  load: async loadOptions => {
    const options = {}
    const sort = (loadOptions.sort && loadOptions.sort.length > 0) ? loadOptions.sort : null
    const pager = new Pager(options, sort)
    pager.setPageNumber(loadOptions.skip, loadOptions.take)
    let sortOrder = ''
    if (sort) {
      pager.setSortQuery(sort)
      sortOrder = `&sort=${pager.toCreateSortOrder()}`
    }
    let pageableQuery = `${pager.toCreatePageable()}${sortOrder}`
    const filters = (loadOptions.searchValue) ? loadOptions.searchValue : null

    const excludeModificationTimeSort = filters !== null && filters.itemAction !== 'FBM'
    const modificationDateSortPattern = /&sort=modificationTime,(asc|desc)/
    if (filters === null) return {}
    if (excludeModificationTimeSort && modificationDateSortPattern.test(pageableQuery)) {
      pageableQuery = pageableQuery.replace(modificationDateSortPattern, '')
    }
    const response = await inventoryService.fetchByQuery(filters, pageableQuery)
    const data = response.data
    return {
      data: data.content,
      totalCount: data.totalElements,
    }
  },
})

const inventoryDataSource = new DataSource({
  store: store,
  requireTotalCount: true,
})

export {
  inventoryDataSource as InventoryDataSource,
}
