const chargeTypes = [
  {
    key: 'prep_material',
    value: 0,
    text: 'Prep Material',
  },
  {
    key: 'shipping',
    value: 5,
    text: 'Shipping',
  },
  {
    key: 'service',
    value: 3,
    text: 'Service',
  },
  {
    key: 'return_charge',
    value: 6,
    text: 'Return Charge',
  },
  {
    key: 'extra_charge',
    value: 7,
    text: 'Extra Charge',
  },
  {
    key: 'other',
    value: 10,
    text: 'Other',
  },
]
export default {
  getChargeTypes() {
    return chargeTypes
  },
}
