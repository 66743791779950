<template>
    <div class="container-fluid px-0">
        <div class="row">
            <div class="col-md-12">
                <dx-data-grid
                    id="uploadBatchGrid"
                    ref="uploadBatchGridRef"
                    :height="tableHeight"
                    :data-source="dataSource"
                    :allow-column-reordering="true"
                    :allow-column-resizing="true"
                    :column-auto-width="true"
                    :show-column-lines="true"
                    :show-row-lines="true"
                    :show-borders="true"
                    :hover-state-enabled="true"
                    :row-alternation-enabled="false"
                    @exporting="onExporting"
                >
                    <dx-export
                      :enabled="true"
                      :formats="['pdf']"
                      :allow-export-selected-data="false"
                    />
                    <dx-column data-field="title" />
                    <dx-column data-field="asin" />
                    <dx-column data-field="upc" />
                    <dx-column data-field="fnsku" />
                    <dx-column data-field="msku" />
                    <dx-column data-field="quantity" alignment="left" />
                    <dx-column data-field="sentCount" caption="Shipt Quantity" alignment="left" />
                    <dx-column data-field="itemAction" />
                    <dx-column data-field="companyName" />
                    <dx-column data-field="storeName" />
                    <dx-column data-field="warehouseName" />
                    <dx-column data-field="locationName" />
                    <dx-column caption="Actions" alignment="center" cell-template="itemActions" :visible="showAction" />
                    <template #itemActions="{data}">
                      <div>
                        <dx-util-button type="default" icon="bi-cart-fill" text="Move Inventory" @click="onClickMoveInventory(data)" />
                      </div>
                    </template>
                </dx-data-grid>
            </div>
            <div class="col-md-12">
              <!--Begin:: Move Items Form-->
              <dx-util-popup
                ref="moveInventoryPopupRef"
                :show-close-button="false"
                :drag-enabled="false"
                :close-on-outside-click="false"
                :show-title="true"
                :width="480"
                :height="360"
                title="Move Inventory to New Location"
                @initialized="onPopupOnitialized"
                @hidden="onPopupHidden"
              >
              <dx-util-position at="center" my="center" />
              <dx-util-toolbar-item widget="dxButton" toolbar="bottom" location="before" :options="saveButtonOptions" />
              <dx-util-toolbar-item widget="dxButton" toolbar="bottom" location="after" :options="cancelButtonOptions" />
              <form @submit="handleSubmit">
                <dx-util-form ref="moveInventoryFormRef" :form-data="moveInventoryFormData" :col-count="1" :show-colon-after-label="true" label-location="top">
                  <dx-util-simple-item data-field="location">
                      <dx-util-required-rule message="This field is required" />
                  </dx-util-simple-item>
                  <dx-util-simple-item data-field="quantity" :editor-options="quantityOptions" editor-type="dxNumberBox">
                      <dx-util-required-rule message="This field is required" />
                  </dx-util-simple-item>
                </dx-util-form>
              </form>
              </dx-util-popup>
              <!--End:: Move Items Form-->
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import inventoryService from '@/http/requests/inventory/inventoryService'
import GridBase from '@core/dev-extreme/mixins/grid/base'
import useCurrentUser from '@/libs/app/current-user'
import Pager from '@core/dev-extreme/utils/pager'
import { Notify } from '@robustshell/utils/index'

import { jsPDF } from 'jspdf'
import { exportDataGrid } from 'devextreme/pdf_exporter'

export default {
  mixins: [GridBase],
  props: {
    filters: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    showAction: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  setup() {
    const { userWarehouseId } = useCurrentUser()
    return {
      userWarehouseId,
    }
  },
  data() {
    return {
      dataSource: [],
      quantityOptions: {
        min: 1,
        step: 1,
        showSpinButtons: true,
      },
      quantityOptionsDefault: {},
      moveInventoryFormData: {
        id: null,
        location: null,
        quantity: 1,
      },
      moveInventoryFormDataDefault: {},
    }
  },
  computed: {
    moveInventoryPopup() {
      return this.$refs.moveInventoryPopupRef.instance
    },
    saveButtonOptions() {
      return {
        text: 'Save',
        type: 'success',
        useSubmitBehavior: true,
        onClick: e => {
          this.handleSubmit(e.event)
        },
      }
    },
    cancelButtonOptions() {
      return {
        text: 'Cancel',
        type: 'danger',
        onClick: () => {
          this.moveInventoryPopup.hide()
        },
      }
    },
  },
  mounted() {
    this.moveInventoryFormDataDefault = { ...this.moveInventoryFormData }
    this.quantityOptionsDefault = { ...this.quantityOptions }
  },
  methods: {
    onPopupOnitialized(e) {
      e.component.registerKeyHandler('escape', arg => {
        arg.stopPropagation()
      })
    },
    onPopupHidden(e) {
      this.moveInventoryFormData = { ...this.moveInventoryFormDataDefault }
      this.quantityOptions = { ...this.quantityOptionsDefault }
    },
    onShownItemsLocation() {
      this.dataSource.splice(0, this.dataSource.length)
      this.getItems()
    },
    getItems() {
      const pager = new Pager({})
      inventoryService.fetchItemsByLocation(this.filters, pager.staticPageable).then(result => {
        this.dataSource = [...result.data.content]
      })
    },
    tableHeight() {
      return window.innerHeight / 1.125
    },
    onClickMoveInventory(e) {
      this.moveInventoryFormData = {
        id: e.row.data.id,
        location: null,
        quantity: 1,
      }
      this.quantityOptions = {
        min: 1,
        step: 1,
        showSpinButtons: true,
        max: e.row.data.quantity,
      }
      this.moveInventoryPopup.show()
    },
    handleSubmit(e) {
      const form = this.$refs.moveInventoryFormRef.instance
      const formValidate = form.validate()
      if (formValidate.isValid) {
        const params = {
          id: this.moveInventoryFormData.id,
          warehouseId: this.userWarehouseId,
          location: this.moveInventoryFormData.location.trim(),
          quantity: this.moveInventoryFormData.quantity,
        }
        inventoryService.moveInventoryByLocation(params)
          .then(result => {
            if (result.status === 200) {
              Notify.success('Items have been moved to new location successfully.')
              this.moveInventoryPopup.hide()
            }
          })
          .then(() => {
            this.getItems(this.filters)
          })
      }
    },
    onExporting(e) {
      // eslint-disable-next-line new-cap
      const doc = new jsPDF()
      exportDataGrid({
        jsPDFDocument: doc,
        component: e.component,
        indent: 5,
      }).then(() => {
        doc.save('Item-Locations.pdf')
      })
    },
  },
}
</script>

<style>

</style>
