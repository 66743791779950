<template>
  <dx-util-popup
    ref="fbmShipmentRef"
    :show-close-button="true"
    :drag-enabled="false"
    :close-on-outside-click="false"
    :show-title="true"
    :full-screen="false"
    width="95vw"
    height="95vh"
    :title="fbmShipmentePopupTitle"
    @shown="onShownFbmShipmentPopup"
    @hidden="onHiddenFbmShipmentPopup"
  >
  <template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
              <div class="card mb-1">
                <div class="card-body p-1">
                  <div class="d-flex flex-row align-items-center w-100 pb-1">
                    <div class="align-self-center mr-1">
                      <dx-util-check-box
                        v-model="isCombinedBox"
                        text="Combined Shipment"
                        :disabled="isCombinedBoxDisabled"
                      />
                    </div>
                    <div class="align-self-center mr-1 w-25">
                      <dx-util-select-box
                        v-model="selectedUserPlan"
                        :data-source="userPlans"
                        display-expr="text"
                        value-expr="value"
                      />
                    </div>
                    <div class="align-self-center mr-1">
                      Row Count:
                      <span class="badge badge-sequare badge-success">
                        {{ selectedItemCount }}
                      </span>
                    </div>
                    <div class="align-self-center mr-1">
                      Total Shipment Items Quantity:
                      <span class="badge badge-sequare badge-danger h-4">
                        {{ totalShipmentQuantity }}
                      </span>
                    </div>
                    <div class="align-self-center ml-auto">
                      <dx-util-button
                        text="Ship"
                        type="success"
                        icon="save"
                        styling-mode="contained"
                        @click="saveShipment"
                      />
                    </div>
                  </div>
                  <dx-data-grid
                    id="fbmShipmentItemsGrid"
                    ref="fbmShipmentItemsGridRef"
                    :data-source="grid.dataSource"
                    height="calc(100vh / 4)"
                    :allow-column-reordering="true"
                    :allow-column-resizing="true"
                    :column-auto-width="true"
                    :show-column-lines="true"
                    :show-row-lines="true"
                    :show-borders="true"
                    :row-alternation-enabled="true"
                    @row-updated="calculateTotalShipmentQuantity"
                    @content-ready="onContentReady"
                  >
                    <dx-editing mode="cell" :allow-updating="true" />
                    <dx-scrolling column-rendering-mode="standard" />
                    <dx-paging :enabled="false" />
                    <dx-column data-field="title" caption="Title" :width="300" :allow-editing="false" />
                    <dx-column data-field="upc" caption="UPC" :allow-editing="false" />
                    <dx-column data-field="asin" caption="ASIN" :allow-editing="false" />
                    <dx-column data-field="itemAction" caption="Action" :allow-editing="false" />
                    <dx-column data-field="quantity" caption="Quantity" :allow-editing="false" data-type="number" alignment="left" :width="128" />
                    <dx-column data-field="shipmentQuantity" data-type="number" alignment="left" :width="128" :editor-options="shipmnetQuantityEditorOptions" />
                    <dx-column data-field="dimLength" data-type="number" alignment="left" :width="128" :editor-options="numberEditorOptions">
                      <dx-async-rule :validation-callback="gridDimensionValidation" message="Shipment Dimensions:Length should be at least 1" />
                    </dx-column>
                    <dx-column data-field="dimWidth" data-type="number" alignment="left" :width="128" :editor-options="numberEditorOptions">
                      <dx-async-rule :validation-callback="gridDimensionValidation" message="Shipment Dimensions:Width should be at least 1" />
                    </dx-column>
                    <dx-column data-field="dimHeight" data-type="number" alignment="left" :width="128" :editor-options="numberEditorOptions">
                      <dx-async-rule :validation-callback="gridDimensionValidation" message="Shipment Dimensions:Height should be at least 1" />
                    </dx-column>
                    <dx-column data-field="weight" data-type="number" alignment="left" :width="128" :editor-options="numberEditorOptions" />
                    <dx-column data-field="notes" :width="300" />
                  </dx-data-grid>
                </div>
              </div>
            </div>
          <div class="col-md-12">
            <div class="card">
              <div class="card-body p-1">
                <div class="d-flex mb-1">
                  <div class="mr-auto p-0 align-self-center">
                      <h4 class="font-weight-normal">
                        Charges
                      </h4>
                  </div>
                  <div class="p-0 mr-1 align-self-center">
                    <span class="badge badge-danger p-1">{{ charges.length }} Charge added </span>
                  </div>
                  <div class="p-0 align-self-center">
                    <dx-util-button text="Add Charge" type="success" icon="insertcolumnleft" styling-mode="contained" @click="addCharge" />
                  </div>
                </div>
                <div v-for="(item, index) in charges" :key="item.rowId" class="d-flex flex-row w-100 mb-1 justify-content-start align-items-center">
                    <div class="align-self-center flex-grow-1 mr-1">
                      <dx-util-select-box
                        v-model="item.selectedRowId"
                        :data-source="grid.items"
                        :drop-down-options="selectBoxAttributes"
                        display-expr="text"
                        value-expr="value"
                      />
                    </div>
                    <div class="align-self-center flex-grow-1 mr-1">
                      <dx-util-select-box
                        v-model="item.chargeType"
                        :data-source="chargeTypes"
                        display-expr="text"
                        value-expr="key"
                        @value-changed="onChangeChargeType($event, item.rowId)"
                      />
                    </div>
                    <div class="align-self-center mr-1">
                      <dx-util-text-box :disabled="!item.isPrepMaterial" placeholder="Scan Prep Material" @enter-key="onScanBarcode($event, item.rowId)" />
                    </div>
                    <div v-if="item.isPrepMaterial" class="align-self-center flex-grow-1 mr-1">
                      <dx-util-autocomplete
                        v-model="item.costItem"
                        :data-source="filteredCostItems"
                        :min-search-length="3"
                        :max-item-count="30"
                        :show-clear-button="false"
                        value-expr="title"
                        search-expr="title"
                        placeholder="Prep Material Name"
                        @selection-changed="onSetChargeItemData($event, item.rowId)"
                      >
                        <dx-util-validator>
                          <dx-util-required-rule message="Count is required" />
                        </dx-util-validator>
                      </dx-util-autocomplete>
                    </div>
                    <div v-else class="align-self-center flex-grow-1 mr-1">
                      <dx-util-text-box v-model="item.notes" placeholder="Charge Note" />
                    </div>
                    <div class="align-self-center mr-1">
                      <dx-util-number-box v-model="item.rate" :element-attr="inputClass" :read-only="item.isPrepMaterial" :hover-state-enabled="false" />
                    </div>
                    <div class="align-self-center mr-1">
                      <dx-util-number-box
                        v-model="item.count"
                        :element-attr="inputClass"
                        :min="1"
                        :max="maxInt"
                        :show-spin-buttons="true"
                      >
                        <dx-util-validator>
                          <dx-util-required-rule message="Count is required" />
                        </dx-util-validator>
                      </dx-util-number-box>
                    </div>
                    <div class="align-self-center">
                      <dx-util-button
                        hint="Remove Charge"
                        type="danger"
                        icon="close"
                        styling-mode="contained"
                        @click="removeBoxCharge($event, index)"
                      />
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  </template>
</dx-util-popup>
</template>

<script>
/* eslint-disable no-return-await */
/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-param-reassign */

import { v4 as uuidv4 } from 'uuid'
import find from 'lodash/find'
import filter from 'lodash/filter'
import _ from 'lodash'
import prepMaterialInventoryService from '@/http/requests/prep-material/prepMaterialInventoryService'
import outboundShippingService from '@/http/requests/outbound/outboundShippingService'
import productsService from '@/http/requests/product/productsService'
import PlanSearchFilter from '@/http/models/search-filters/PlanSearchFilter'
import planManagementService from '@/http/requests/plan/planManagementService'
import Pageable from '@robustshell/components/Grid/pageable'
import GridBase from '@core/dev-extreme/mixins/grid/gridBase'
import Pager from '@core/dev-extreme/utils/pager'
import { Notify } from '@robustshell/utils/index'
import charge from './chargeType'

export default {
  mixins: [GridBase],
  props: {
    selectedRows: {
      type: Array,
      required: true,
    },
    notCombined: {
      type: Boolean,
      require: false,
      default: true,
    },
    componentId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      fbmShipmentePopupTitle: '',
      selectBoxAttributes: {
        minWidth: '600px',
      },
      inputClass: {
        class: 'w-100',
      },
      warehouseId: 0,
      companyId: 0,
      storeId: 0,
      itemAction: 'FBM',
      chargeTypes: [],
      userPlans: [],
      selectedUserPlan: 0,
      isCombinedBox: false,
      isCombinedBoxDisabled: false,
      grid: {
        dataSource: [],
        items: [],
      },
      filteredCostItems: [],
      charges: [],
      productInfo: [],
      pageable: new Pageable({ pageSize: 500 }),
      selectedItemCount: 0,
      totalShipmentQuantityItems: [],
    }
  },
  computed: {
    fbmShipmentPopupIns() {
      return this.$refs.fbmShipmentRef.instance
    },
    maxInt() {
      return Number.MAX_SAFE_INTEGER
    },
    numberEditorOptions() {
      return {
        showSpinButtons: false,
        min: 0,
        mode: 'number',
        onFocusIn: e => {
          e.event.target.value = null
        },
      }
    },
    shipmnetQuantityEditorOptions() {
      return {
        showSpinButtons: false,
        min: 0,
        mode: 'number',
        onFocusIn: e => {
          e.event.target.value = null
        },
      }
    },
    totalShipmentQuantity() {
      const sum = this.totalShipmentQuantityItems.reduce((total, item) => total + item.shitmentQuantity, 0)
      return sum
    },
  },
  watch: {
    componentId: {
      immediate: true,
      handler(current) {
        if (current) {
          // this.dataSourceArr = []
          this.createFbmShipmentPopup()
        }
      },
    },
  },
  created() {
    this.getCostItems()
    this.chargeTypes = charge.getChargeTypes()
  },
  methods: {
    createFbmShipmentPopup() {
      this.fbmShipmentPopupIns.show()
    },
    onShownFbmShipmentPopup() {
      this.fbmShipmentePopupTitle = 'Reconciliation'
      this.onShown()
    },
    onHiddenFbmShipmentPopup() {
      this.onClose()
      this.setDefaults()
    },
    onClose() {
      this.fbmShipmentPopupIns.hide()
    },
    // Validate shipment item quantity on grid datasource
    gridNumberValidation: async param => await (param.value <= param.data.quantity && param.value > 0),
    gridDimensionValidation: async param => await (param.value > 0),
    onShown() {
      const productIds = []
      this.grid.items.push({ value: 'null', text: 'Shipment Box' })
      this.selectedItemCount = this.selectedRows.length
      this.selectedRows.forEach(item => {
        this.warehouseId = item.warehouseId
        this.companyId = item.companyId
        this.storeId = item.storeId
        this.itemAction = item.itemAction
        productIds.push(item.productId)
        const shipmentItem = {
          rowId: uuidv4(),
          asin: item.asin,
          id: item.id,
          companyId: item.companyId,
          upc: item.upc,
          itemAction: item.itemAction,
          productId: item.productId,
          storeId: item.storeId,
          title: item.title,
          warehouseId: item.warehouseId,
          quantity: item.quantity,
          shipmentQuantity: 0,
          dimWidth: 0,
          dimHeight: 0,
          dimLength: 0,
          weight: 0,
          notes: null,
        }
        this.grid.dataSource.push(shipmentItem)
        const comboItem = {
          value: shipmentItem.rowId,
          text: `${item.asin}-${item.title}`,
        }
        this.grid.items.push(comboItem)
      })
      if (productIds.length > 0) {
        productsService
          .findProductAndCharges(productIds)
          .then(result => {
            this.productInfo.splice(0, this.productInfo.length)
            const { data } = result
            data.forEach(product => {
              this.grid.dataSource.forEach(row => {
                if (row.productId === product.product.id) {
                  row.dimWidth = product.product.dimWidth
                  row.dimHeight = product.product.dimHeight
                  row.dimLength = product.product.dimLength
                  row.weight = product.product.weight
                }
                const productItem = {
                  rowId: row.rowId,
                  product: product.product,
                  charges: product.charges,
                }
                this.productInfo.push(productItem)
              })
            })
          })
      }
      if (this.grid.dataSource.length === 1) {
        this.isCombinedBoxDisabled = true
        this.isCombinedBox = true
      } else if (this.grid.dataSource.length > 1) {
        this.isCombinedBox = true
        this.isCombinedBoxDisabled = true
      }
      if (!this.notCombined) {
        this.isCombinedBox = this.notCombined
        this.isCombinedBoxDisabled = this.notCombined
      }
      this.getUserPlans()
    },
    getUserPlans() {
      const pager = new Pager({})
      PlanSearchFilter.setDefaultFilters()
      PlanSearchFilter.warehouseId = this.warehouseId
      PlanSearchFilter.companyId = this.companyId
      const searchFilter = PlanSearchFilter.getFilters()
      this.userPlans.splice(0, this.userPlans.length)
      planManagementService
        .fetchAllUserPlansByQuery(searchFilter, pager.staticPageable)
        .then(result => {
          const data = result.data.content
          if (data.length > 1) {
            this.userPlans.push({ value: 0, text: 'Plans (All)' })
          }
          data.forEach(element => {
            if (element.companyId === this.companyId) {
              this.userPlans.push({
                value: element.id,
                text: `${element.planCategory.toUpperCase()}:${element.planName}:${element.planType}`,
              })
            }
          })
          this.selectedUserPlan = 0
          if (this.userPlans.length === 1) {
            this.selectedUserPlan = this.userPlans[0].value
          }
        })
    },
    getCostItems() {
      const pager = new Pager({ staticPageSize: 500 })
      prepMaterialInventoryService.fetchAll(pager.staticPageable).then(response => {
        this.filteredCostItems.splice(0, this.filteredCostItems.length)
        response.data.content.forEach(item => {
          this.filteredCostItems.push({
            id: item.id,
            title: item.title,
            salePrice: item.salePrice,
          })
        })
      })
    },
    // Box Charge Repeater
    addCharge() {
      if (this.charges.length === 0) {
        this.createItemCharge()
      } else if (this.checkAddCharge()) {
        this.createItemCharge()
      } else {
        Notify.warning('Please enter valid charge value.')
      }
    },
    checkAddCharge() {
      if (this.charges.length > 0) {
        const items = filter(this.charges, item => item.count === 0 || item.rate === 0)
        return (items.length === 0)
      }
      return false
    },
    createItemCharge() {
      this.charges.push({
        rowId: uuidv4(),
        selectedRowId: 'null',
        isPrepMaterial: true,
        id: 0,
        itemId: 0,
        costId: 0,
        costItem: '',
        chargeType: 'prep_material',
        rate: 0,
        count: 1,
        notes: '',
      })
    },
    onScanBarcode(e, rowId) {
      const barcode = e.component.option('value')
      const costItem = find(this.filteredCostItems, item => item.id === parseInt(barcode, 10))
      const chargeItem = find(this.charges, item => item.rowId === rowId)
      if (costItem) {
        chargeItem.id = 0
        chargeItem.itemId = 0
        chargeItem.costId = costItem.id
        chargeItem.costItem = costItem.title
        chargeItem.rate = costItem.salePrice
        chargeItem.count = 1
        chargeItem.notes = null
      }
    },
    onSetChargeItemData(e, rowId) {
      const costItem = e.selectedItem
      const chargeItem = find(this.charges, item => item.rowId === rowId)
      if (costItem) {
        chargeItem.id = 0
        chargeItem.itemId = 0
        chargeItem.costId = costItem.id
        chargeItem.costItem = costItem.title
        chargeItem.rate = costItem.salePrice
        chargeItem.count = 1
        chargeItem.notes = null
      } else {
        chargeItem.chargeType = 'prep_material'
        this.setDefaultBoxChargeItemData(chargeItem)
      }
    },
    setDefaultChargeItemData(item) {
      const chargeItem = item
      chargeItem.isPrepMaterial = false
      chargeItem.id = 0
      chargeItem.itemId = 0
      chargeItem.costId = 0
      chargeItem.costItem = ''
      chargeItem.rate = 0
      chargeItem.count = 1
      chargeItem.notes = ''
    },
    removeBoxCharge(e, index) {
      this.charges.splice(index, 1)
    },
    onChangeChargeType(e, rowId) {
      const chargeItem = find(this.charges, item => item.rowId === rowId)
      this.setDefaultChargeItemData(chargeItem)
      if (e.value === 'prep_material') {
        chargeItem.isPrepMaterial = true
      } else {
        chargeItem.isPrepMaterial = false
      }
    },
    checkUserPlan() {
      if (this.selectedUserPlan === 0) {
        Notify.error('Please select a plan')
        return false
      }
      return true
    },
    calculateTotalShipmentQuantity(e) {
      const item = {
        rowId: e.data.rowId,
        shitmentQuantity: e.data.shipmentQuantity,
      }
      const notExist = this.totalShipmentQuantityItems.filter(c => c.rowId !== item.rowId)
      if (notExist && notExist.length > 0) {
        this.totalShipmentQuantityItems.length = 0
        this.totalShipmentQuantityItems = [...notExist]
      }

      this.totalShipmentQuantityItems.push(item)
    },
    checkCharges() {
      return true
    },
    checkSelectedItem(shipmentItem) {
      if (!this.checkNumbers(shipmentItem.shipmentQuantity)) {
        Notify.error(`ASIN ${shipmentItem.asin}: Shipment Quantity should be at least 1 and less than available quantity`)
        return false
      }
      if (!this.checkNumbers(shipmentItem.dimWidth)) {
        Notify.error(`ASIN ${shipmentItem.asin}: Width should be at least 1`)
        return false
      }
      if (!this.checkNumbers(shipmentItem.dimHeight)) {
        Notify.error(`ASIN ${shipmentItem.asin}: Height should be at least 1`)
        return false
      }
      if (!this.checkNumbers(shipmentItem.dimLength)) {
        Notify.error(`ASIN ${shipmentItem.asin}: Length should be at least 1`)
        return false
      }
      return true
    },
    checkNumbers(value) {
      return value > 0 && typeof value === 'number' && typeof value !== 'undefined'
    },
    saveShipment() {
      let valid = true
      if (!this.checkCharges()) return
      if (!this.checkUserPlan()) return
      const shipmentPackages = []
      const charges = this.groupedCharges()
      const dataGrid = this.$refs.fbmShipmentItemsGridRef.instance
      const dataSource = dataGrid.getDataSource().items()
      let boxCharges = []
      const shipmentPackage = {
        shipmentBox: {
          id: null,
          companyId: this.companyId,
          storeId: this.storeId,
          batchId: null,
          boxName: '',
          boxOrder: null,
          invoiceId: null,
          prepItemCostId: null,
          itemCount: 0,
          status: null,
          carrier: null,
          tracking: null,
          shippedTo: null,
          notes: null,
          warehouseId: this.warehouseId,
          itemAction: this.itemAction,
        },
        items: [],
        boxCharges: [],
      }
      charges.forEach(element => {
        const selectedRowId = element.selectedRowId
        if (selectedRowId === 'null') {
          const addBoxCharges = this.cleanCharges(element.charges)
          boxCharges = [...addBoxCharges]
        }
      })

      dataSource.forEach(element => {
        let costs = []
        if (!this.checkSelectedItem(element)) valid = false
        const item = find(charges, p => p.selectedRowId === element.rowId)
        if (item && item.charges.length > 0) {
          const addItemCharge = this.cleanCharges(item.charges)
          costs = [...addItemCharge]
        }
        const shipmentItemWithCost = this.createShipmentItemWithCosts(element, costs)
        shipmentPackage.items.push(shipmentItemWithCost)
      })

      shipmentPackage.boxCharges = [...boxCharges]
      shipmentPackages.push(shipmentPackage)
      if (valid) {
        this.persistShipmentPackages(shipmentPackages)
      }
    },
    groupedCharges() {
      return _.chain(this.charges)
        .groupBy('selectedRowId')
        .map((value, key) => ({ selectedRowId: key, charges: value }))
        .value()
    },
    cleanCharges(charges) {
      const itemCharges = []
      charges.forEach(item => {
        const costId = (item.costId === 0) ? null : item.costId
        const itemCharge = {
          id: null,
          itemId: null,
          costId: costId,
          chargeType: item.chargeType,
          rate: item.rate,
          count: item.count,
          notes: item.notes,
        }
        itemCharges.push(itemCharge)
      })
      return itemCharges
    },
    createShipmentItemWithCosts(selectedItem, costs) {
      const shipmentItemWithCost = {
        item: {
          id: selectedItem.id,
          companyId: selectedItem.companyId,
          storeId: selectedItem.storeId,
          shipmentBatchId: null,
          inventoryItemId: selectedItem.id,
          invoiceId: null,
          prepItemCostId: null,
          productId: selectedItem.productId,
          planId: this.selectedUserPlan,
          shipmentBoxId: 0,
          quantity: selectedItem.shipmentQuantity,
          receiveTime: null,
          prepTime: null,
          shipTime: null,
          deliveryTime: null,
          reconcileTime: null,
          isDelivered: false,
          status: null,
          carrier: null,
          tracking: null,
          shippedTo: null,
          notes: selectedItem.notes,
        },
        product: {
          productId: selectedItem.productId,
          planId: this.selectedUserPlan,
          dimLength: selectedItem.dimLength,
          dimHeight: selectedItem.dimHeight,
          dimWidth: selectedItem.dimWidth,
          weight: selectedItem.weight,
          updateDefaultCharges: false,
        },
        costs: costs,
      }
      return shipmentItemWithCost
    },
    persistShipmentPackages(shipmentPackages) {
      outboundShippingService.createShipmentPackages(shipmentPackages).then(() => {
        this.selectedRows.splice(0, this.selectedRows.length)
        this.$emit('clear', shipmentPackages)
        this.onClose()
      })
    },
    setDefaults() {
      this.grid.dataSource.splice(0, this.grid.dataSource.length)
      this.grid.items.splice(0, this.grid.items.length)
      this.charges.splice(0, this.charges.length)
      this.isCombinedBox = false
      this.isCombinedBoxDisabled = false
      this.warehouseId = 0
      this.companyId = 0
      this.storeId = 0
    },
  },
}
</script>

<style></style>
