/* eslint-disable lines-between-class-members */
import BaseSearchFilter from './BaseSearchFilter'

export class InventorySearchFilter extends BaseSearchFilter {
  /** @type {string} */
  #itemAction = 'ALL'
  /** @type {string} */
  #inventoryType = 'ALL'
  /** @type {boolean} */
  #hasInventory = true
  /** @type {string} */
  #location = ''
  /** @type {boolean} */
  #includeAllShipped = false
  /** @type {boolean} */
  #locationRequired = false
  constructor() {
    super()
    this.#itemAction = this.itemAction
    this.#includeAllShipped = this.includeAllShipped
    this.#locationRequired = this.locationRequired
  }

  setDefaultFilters() {
    this.accountNo = ''
    this.companyId = 0
    this.storeId = 0
    this.warehouseId = 0
    this.itemAction = 'ALL'
    this.beginDate = null
    this.endDate = null
    this.tenantType = 'SERVING'
    this.includeAllShipped = false
    this.locationRequired = false
    this.hasInventory = true
    this.inventoryType = 'ALL'
    this.q = ''
    this.location = ''
  }

  /** @returns {Object} filter object */
  getFilters() {
    const filter = {
      accountNo: this.accountNo,
      companyId: this.companyId,
      storeId: this.storeId,
      warehouseId: this.warehouseId,
      itemAction: this.itemAction,
      includeAllShipped: this.includeAllShipped,
      locationRequired: this.locationRequired,
      inventoryType: this.inventoryType,
      hasInventory: this.hasInventory,
      beginDate: this.beginDate,
      endDate: this.endDate,
      tenantType: this.tenantType,
      q: this.q,
      location: this.location,
    }
    return filter
  }

  /** @returns {string} */
  get itemAction() {
    return this.#itemAction
  }
  /** @param {string} value */
  set itemAction(value) {
    this.#itemAction = value
  }
  /** @returns {string} */
  get inventoryType() {
    return this.#inventoryType
  }
  /** @param {string} value */
  set inventoryType(value) {
    this.#inventoryType = value
  }
  /** @returns {boolean} */
  get includeAllShipped() {
    return this.#includeAllShipped
  }
  /** @param {boolean} value */
  set includeAllShipped(value) {
    this.#includeAllShipped = value
  }
  /** @returns {boolean} */
  get hasInventory() {
    return this.#hasInventory
  }
  /** @param {boolean} value */
  set hasInventory(value) {
    this.#hasInventory = value
  }
  /** @returns {boolean} */
  get locationRequired() {
    return this.#locationRequired
  }
  /** @param {boolean} value */
  set locationRequired(value) {
    this.#locationRequired = value
  }

  /** @returns {string} */
  get location() {
    return this.#location
  }
  /** @param {string} value */
  set location(value) {
    this.#location = value
  }
}
export default new InventorySearchFilter()
