export default {
  fba: {
    key: 'fba',
    normalized: 'FBA',
    description: 'Prep Items List',
  },
  wfs: {
    key: 'wfs',
    normalized: 'WFS',
    description: 'WFS Items List',
  },
  fbm: {
    key: 'fbm',
    normalized: 'FBM',
    description: 'FBM Items List',
  },
  storage: {
    key: 'storage',
    normalized: 'STORAGE',
    description: 'Storage Items List',
  },
  forwarding: {
    key: 'forwarding',
    normalized: 'FORWARDING',
    description: 'Forwarding Items List',
  },
  remove_order: {
    key: 'removal_order',
    normalized: 'REMOVAL_ORDER',
    description: 'Remove Items List',
  },
  b2b: {
    key: 'b2b',
    normalized: 'B2B',
    description: 'B2B Items List',
  },
  dropship: {
    key: 'dropship',
    normalized: 'DROPSHIP',
    description: 'Dropship Items List',
  },
  return: {
    key: 'return',
    normalized: 'RETURN',
    description: 'Return Items List',
  },
}
