<template>
  <div class="">
    <div v-if="!editable" class="d-flex justify-content-around">
      <span>
        {{ row.value }}
      </span>
      <p-icon role="button" name="bi-pencil-square" class="text-primary" @click.native="editable = true" />
    </div>
    <dx-util-number-box
      v-else
      :ref="`textbox-quantity-${row.data.id}`"
      :value="row.value"
      :min="0"
      @content-ready="setEditable"
      @value-changed="updateQuantityInForm"
      @focus-in="onFocusInUpdate(row)"
      @focus-out="editable=false,quantityDiff=0"
      @key-down="onKeyDown($event, row)"
      @enter-key="onEnterKey"
    >
      <dx-text-box-button
        :name="`quantity-${row.data.id}`"
        :options="{
          icon: 'save',
          type: 'success',
          elementAttr: { id: `quantity-${row.data.id}` },
          onClick: () => onUpdateQuantity(row)
          }"
        location="after"
      />
  </dx-util-number-box>
  </div>
</template>

<script>
import { DxButton as DxTextBoxButton } from 'devextreme-vue/text-box'
import inventoryService from '@/http/requests/inventory/inventoryService'
import { Notify } from '@robustshell/utils/index'

export default {
  components: {
    'dx-text-box-button': DxTextBoxButton,
  },
  props: {
    row: {
      type: Object,
      required: true,
    },
    inventoryType: {
      type: String,
      default: 'available',
      validator: value => ['available', 'reserved', 'on-hand'].includes(value),
    },
  },
  data() {
    return {
      editable: false,
      newQuantity: null,
      quantityDiff: 0,
    }
  },
  methods: {
    updateQuantityInForm(e) {
      this.newQuantity = e.value
      this.quantityDiff = e.value - e.previousValue
    },
    setEditable(e) {
      e.component.focus()
      this.onFocusInUpdate(this.row)
    },
    onUpdateQuantity(row) {
      if (this.quantityDiff === 0 && this.inventoryType === 0) {
        Notify.info('No change has been detected.')
      }
      if (!row.data.id) return
      const item = { ...row.data }
      this.inventoryQuantityFormData = {
        productId: item.productId,
        mskuFnskuPairId: item.mskuFnskuPairId,
        id: item.id,
        accountNo: item.accountNo,
        companyId: item.companyId,
        storeId: item.storeId,
        warehouseId: item.warehouseId,
        location: item.locationName,
        itemAction: item.itemAction,
      }
      this.inventoryQuantityFormData.currentQuantity = this.inventoryType === 'reserved' ? item.reserved : item.quantity
      this.inventoryQuantityFormData.quantity = item.quantity

      if (this.inventoryType === 'on-hand') {
        this.inventoryQuantityFormData.newQuantity = item.quantity + this.quantityDiff
      }

      if (this.inventoryType !== 'on-hand') this.inventoryQuantityFormData.newQuantity = this.newQuantity

      if (this.inventoryQuantityFormData.currentQuantity !== this.inventoryQuantityFormData.newQuantity) {
        if (this.inventoryType === 'reserved') {
          inventoryService.updateReserveItemsByProductId(this.inventoryQuantityFormData).then(() => {
            this.editable = false
            this.quantityDiff = 0
            this.$emit('on-emit-quantity-updated')
          })
        }
        if (this.inventoryType === 'available') {
          inventoryService.decrementItemQuantity(this.inventoryQuantityFormData).then(() => {
            this.editable = false
            this.quantityDiff = 0
            this.$emit('on-emit-quantity-updated')
          })
        }
        if (this.inventoryType === 'on-hand') {
          inventoryService.decrementItemQuantity(this.inventoryQuantityFormData).then(() => {
            this.editable = false
            this.quantityDiff = 0
            this.$emit('on-emit-quantity-updated')
          })
        }
      } else {
        Notify.info('No change has been detected.')
      }
    },
    onFocusInUpdate(row) {
      if (this.inventoryType === 'reserved') {
        this.newQuantity = row.data.reserved
      }
      if (this.inventoryType === 'available') {
        this.newQuantity = row.data.quantity
      }
      if (this.inventoryType === 'on-hand') {
        this.newQuantity = row.data.onHand
      }
    },
    onKeyDown(e) {
      const escapeKey = 27
      if (this.editable && e.event.which === escapeKey) {
        this.editable = false
      }
    },
    onEnterKey() {
      this.onUpdateQuantity(this.row)
    },
  },

}
</script>

<style lang="scss" scoped>

</style>
