<template>
  <div class="dx-product-title text-info" @click="openProductDetails">
    <span style="font-size: 15px;">
      {{ product.title }}
    </span>
    <div class="d-flex justify-content-start mt-half align-items-end">
      <!-- <div class="col-2 px-half">
        <span class="badge badge-light-primary text-uppercase mr-4">
          {{ product.packType }}
        </span>
      </div> -->
      <!-- <div class="col-2 px-half">
        Hazmat: <p-icon name="psh-flame" :color="product.isHazmat ? 'danger': 'gray'" :size="product.isHazmat ? '28px': '24px'" class="mr-4" />
      </div> -->
      <div class="col-4 px-half">
        <span v-if="product.amzRank" class="badge badge-light-success">Rank: {{ product.amzRank }}</span>
      </div>
      <div class="col-8 px-half ml-1">
        <span class="text-muted">
          Product Category: {{ product.productType || '-' }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    product: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    openProductDetails() {
      this.$emit('emit-open-product-details', this.product.productId)
    },
    resolvePackType(val) {
      if (val === 'individual') return 'badge-light-primary'
      if (val === 'bundle') return 'badge-light-warning'
      return ''
    },
    resolveStatusVariant(tier) {
      if (tier === 1) return 'secondary'
      return 'warning'
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
