<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12 px-0">
        <dx-data-grid
          id="inventoryGrid"
          ref="inventoryGridRef"
          :height="setHeight"
          :data-source="InventoryDataSource"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :column-auto-width="true"
          :show-column-lines="true"
          :show-row-lines="true"
          :show-borders="true"
          :row-alternation-enabled="false"
          :hover-state-enabled="true"
          :customize-columns="customizeColumns"
          @initialized="onInitialized"
          @option-changed="onOptionChanged"
          @content-ready="onContentReady"
          @cell-dbl-click="onCellDblClick"
          @toolbar-preparing="onToolbarPreparing($event)"
          @cell-prepared="onCellPrepared($event)"
          @editor-preparing="onEditorPreparing"
        >
          <!--region    ::DataGird base components -->
          <dx-state-storing
            :enabled="true"
            type="custom"
            :custom-load="loadDataGridState"
            :custom-save="saveDataGridState"
          />
          <dx-sorting mode="single" />
          <template #buttons="{ data }">
            <div>
              <div v-for="item in data" :key="item.id" class="">
                <dx-util-button :text="item.name" :icon="item.icon" class=" w-100 text-left" styling-mode="text" @click="setActionByItem(item)" />
              </div>
            </div>
          </template>
          <dx-remote-operations :paging="true" :filtering="true" :sorting="true" />
          <dx-column-chooser :enabled="true" mode="select" title="Column Chooser" />
          <dx-search-panel
            :highlight-search-text="true"
            :visible="true"
            :width="200"
            placeholder="Search..."
          />
          <dx-selection
            show-check-boxes-mode="always"
            select-all-mode="page"
            :allow-select-all="true"
            :width="10"
            mode="multiple"
          />
          <dx-keyboard-navigation :enabled="false" />
          <dx-paging :page-size="pagerOptions.pageSize" />
          <dx-pager
            :allowed-page-sizes="pagerOptions.allowedPageSizes"
            :show-info="pagerOptions.showInfo"
            :show-navigation-buttons="pagerOptions.showNavigationButtons"
            :show-page-size-selector="pagerOptions.showPageSizeSelector"
            :visible="pagerOptions.visible"
          />
          <dx-column data-field="id" caption="Actions" alignment="center" cell-template="itemActions" :width="160" :allow-sorting="false"/>
          <template #itemActions="{data}">
            <div>
              <dx-util-button :visible="hasPermission || isIndividualTenant"
                type="default" icon="map"
                class="mr-half" hint="Items Location"
                @click="onClickGetItemLocation(data)"
              />
              <dx-util-button
                type="success" icon="bi bi-file-bar-graph"
                hint="Sales Order Metrics"
                @click="onOpenOrderMetrics(data)"
              />
              <dx-util-drop-down-button
                v-if="data.data.itemAction !== 'FBA' && hasUpdateInventoryPermission"
                id="quantityEditActions"
                ref="quantityEditActionsRef"
                :split-button="false"
                :use-select-mode="false"
                :items="quantityEditActions"
                :drop-down-options="{ width: 170 }"
                styling-mode="contained"
                class="mr-half"
                icon="edit"
                display-expr="name"
                key-expr="id"
                item-template="item-temp"
              >
                <template #item-temp="{data: item}">
                  <dx-util-button
                    :id="item.btnId"
                    :text="item.name"
                    :visible="item.visible"
                    :class="item.class"
                    :element-attr="item.elementAttr"
                    width="170"
                    @click="onEditQuantityAndReservedClink($event, data, item.id)"
                  />
                </template>
              </dx-util-drop-down-button>
            </div>
          </template>
          <!--endregion ::DataGird base components -->
          <dx-column data-field="smallImage" cell-template="imageCellTemplate" :width="100" caption="Image" alignment="center" :allow-sorting="false" />
          <template #imageCellTemplate="{data}">
            <p-lazy-img width="72px" height="72px" :src="data.value || defaultImg" />
          </template>
          <dx-column data-field="title" cell-template="titleCellTemplate" />
          <template #titleCellTemplate="{data}">
            <div>
              <inventory-product-info-card :product="data.data" @emit-open-product-details="openProductDetails" />
            </div>
          </template>
          <dx-column data-field="onHand" caption="On Hand" :width="90" alignment="right" cell-template="onHandTemplate" />
          <template #onHandTemplate="{data}">
            <span v-if="data.data.itemAction !== 'FBA' || (!hasPermission && !isIndividualTenant)">
              {{ data.value }}
            </span>
            <edit-quantity v-else :row="data" :inventory-type="'on-hand'" @on-emit-quantity-updated="onRowChanged()" />
          </template>
          <dx-column data-field="quantity" :caption="itemAction === 'FBM' ? 'Not Listed' : 'Available'" :width="90" alignment="right" cell-template="availableTemplate" />
          <template #availableTemplate="{data}">
            <span v-if="data.data.itemAction !== 'FBA' || (!hasPermission && !isIndividualTenant)">
              {{ data.value }}
            </span>
            <edit-quantity v-else :row="data" :inventory-type="'available'" @on-emit-quantity-updated="onRowChanged()" />
          </template>
          <dx-column data-field="reserved" :caption="itemAction === 'FBM' ? 'Listed' : 'Reserved'" :width="90" alignment="right" cell-template="reservedTemplate" />
          <template #reservedTemplate="{data}">
            <span v-if="data.data.itemAction !== 'FBA' || (!hasPermission && !isIndividualTenant)">
              {{ data.value }}
            </span>
            <edit-quantity v-else :row="data" :inventory-type="'reserved'" @on-emit-quantity-updated="onRowChanged()" />
          </template>
          <dx-column data-field="sentCount" :caption="itemAction === 'FBM' ? 'Sold' : 'Shipped'" alignment="right" :width="60" />
          <dx-column data-field="amzInbound" caption="AMZ Inbound" :width="110" :visible="itemAction === 'FBA'" header-cell-template="headerInfo" />
          <template #headerInfo="{ data }">
            <div :id="data.column.dataField">
              <span v-b-tooltip.d100.hover.bottom.v-primary title="Amazon Inbound and Receiving Total Quantity">
                {{ data.column.caption }}
              </span>
              <i class="bi bi-question-circle text-warning" />
            </div>
          </template>
          <dx-column data-field="amzTotal" caption="AMZ Available" :visible="itemAction === 'FBA'" cell-template="amzAvailableTemplate" :width="110" />
          <template #amzAvailableTemplate="{data}">
            <div :id="`amz-${data.data.id}`">
              <a href="#" @click="togglePopover(data)">{{ data.value }}</a>
            </div>
          </template>
          <dx-column data-field="tracking" :width="150" :visible="showTrackInfo" />
          <dx-column data-field="orderNumber" :width="150" :visible="showTrackInfo" />
          <dx-column data-field="upc" caption="UPC/EAN/GTIN" :width="120" cell-template="upcCellTemplate" />
          <template #upcCellTemplate="{data}">
            <a target="_blank" :href="`https://www.barcodelookup.com/${data.value}`">
              {{ data.value }}
            </a>
          </template>
          <dx-column data-field="asin" caption="ASIN/Item ID" :width="120" cell-template="asinCellTemplate" />
          <template #asinCellTemplate="{data}">
            <a target="_blank" :href="`https://www.amazon.com/gp/product/${data.value}`">
              {{ data.value }}
            </a>
          </template>
          <dx-column data-field="msku" caption="MSKU" :width="140" />
          <dx-column v-if="itemAction !== 'WFS'" data-field="fnsku" caption="FNSKU" :width="100" />
          <dx-column data-field="serialNo" :width="100" />
          <dx-column data-field="palletId" :width="90" />
          <dx-column data-field="modificationTime" caption="Modified At" :visible="itemAction === 'FBM'" data-type="date" :format="'yyyy-MM-dd, HH:mm'" :allow-filtering="false" />
          <dx-column data-field="expirationDate" caption="Exp Date" data-type="date" :allow-filtering="false" :width="100" />
          <dx-column data-field="itemAction" caption="Action" :width="60" />
          <dx-column v-if="hasPermission" data-field="accountNo" caption="Acc #" :width="80" alignment="center" cell-template="accountCell" />
          <dx-column v-if="hasPermission" data-field="suiteNo" caption="Suite #" :width="80" :visible="false" alignment="center" cell-template="accountCell" />
          <dx-column v-if="hasPermission" data-field="companyName" caption="Company" :width="110" cell-template="accountCell" :visible="false" />
          <template #accountCell="{ data }">
            <a href="#" @click="onFilterByAccountNoLink($event, data)">
              <span>{{ data.value }}</span>
            </a>
          </template>
          <dx-column data-field="storeName" caption="Store" :width="110" cell-template="storeCell" />
          <template #storeCell="{ data }">
            <div class="text-center">
              <p-lazy-img height="32px" width="auto" :src="require(`@/assets/images/svg/ecommerce/${data.data.platform.toLowerCase()}.svg`) || defaultImg" />
              <a href="#" @click="onFilterByStoreLink($event, data)">
              <span>{{ data.value }}</span>
            </a>
            </div>
          </template>
          <dx-column v-if="hasPermission" data-field="location" :visible="false" :width="80" />
          <dx-column v-if="hasPermission" data-field="locationName" caption="Location" :width="100" />
          <dx-column v-if="hasPermission" data-field="lpn" caption="LPN" :width="100" :visible="false" />
          <dx-column data-field="notes" :width="250" :visible="showTrackInfo" />
          <template #toolbarFilters>
            <div class="row">
              <div v-if="hasPermission" class="col-lg-3 col-md-6 col-12">
                <dx-util-text-box
                  v-model.trim="accountNo"
                  :show-clear-button="true"
                  mode="text"
                  placeholder="Account or Suite No"
                  @key-down="onFilterByAccountNo"
                  @value-changed="onFilterByAccountNo"
                />
              </div>
              <div class="col-lg-3 col-md-6 col-12">
                <dx-util-select-box
                  v-model="selectedWarehouse"
                  :data-source="warehouses"
                  display-expr="text"
                  value-expr="value"
                  @value-changed="searchByFilter"
                />
              </div>
              <div class="col-lg-3 col-md-6 col-12">
                <dx-util-select-box
                  v-model="selectedStore"
                  :data-source="stores"
                  display-expr="text"
                  value-expr="value"
                  @value-changed="searchByFilter"
                />
              </div>
              <div class="col-lg-3 col-md-6 col-12 d-flex align-items-center">
                <dx-util-check-box
                  v-model="includeAllShipped"
                  text="Show All Shipped"
                  @value-changed="onFilterChangeAllShipped"
                />
              </div>
            </div>
          </template>
        </dx-data-grid>
      </div>
    </div>
    <dx-util-popover
        :target="hoveredItem"
        position="right"
        :defer-rendering="false"
        :close-on-outside-click="true"
        :show-title="true"
        title="Amazon Inventory"
        :width="400"
        :visible="isPopoverVisible"
        :show-close-button="true"
        @hiding="closePopover"
      >
      <template>
        <div>
          <amazon-snapshot :available="selectedAmzAvailableData" />
        </div>
      </template>
    </dx-util-popover>
    <dx-util-popup
      ref="quantityPopupRef"
      :show-close-button="false"
      :drag-enabled="false"
      :close-on-outside-click="false"
      :show-title="true"
      :width="640"
      :height="360"
      :title="editPopup.header"
    >
      <dx-util-position at="center" my="center" />
      <dx-util-toolbar-item toolbar="bottom" location="before" template="submitPopupButton" />
      <template #submitPopupButton>
        <dx-util-button id="submitQuantityUpdateButton" text="Save" type="default" @click="onSubmitUpdate()" />
      </template>
      <dx-util-toolbar-item toolbar="bottom" location="after" template="cancelPopupButton" />
      <template #cancelPopupButton>
        <dx-util-button id="cancelQuantityUpdateButton" text="Cancel" type="danger" @click="onCancelQuantityUpdate()" />
      </template>
      <div>
        <h5 class="text-warning">
          {{ editPopup.title }}
        </h5>
        <h5 class="text-success">
          {{ editPopup.content }}
        </h5>
        <dx-util-form
          ref="inventoryQuantityFormRef"
          :form-data="inventoryQuantityFormData"
          :col-count="1"
          label-location="top"
        >
          <dx-util-simple-item data-field="newQuantity" :editor-options="newQuantityOptions" editor-type="dxNumberBox">
            <dx-util-required-rule message="This field is required" />
          </dx-util-simple-item>
        </dx-util-form>
      </div>
    </dx-util-popup>
    <prep-inverntory-createoredit :id="id" />
    <reconcile-with-file
      :company-info="companyInfoParams"
      :component-id="reconcileWithFileComponentId"
      @on-reload-page="onReloadPage"
    />
    <reconcile-with-wfs-file
      :company-info="companyInfoParams"
      :component-id="reconcileWithWFSFileComponentId"
      @on-reload-page="onReloadPage"
    />
    <move-inventory
      :selected-items="selectedRows"
      :company-info="companyInfoParams"
      :stores="stores"
      :item-action="itemAction"
      :component-id="moveInventoryComponentId"
      @on-item-saved="onRowChanged"
    />
    <fbm-shipment
      :selected-rows="selectedRows"
      :not-combined="notCombined"
      :component-id="fmbShipmnetComponentId"
      @clear="onClearSelected"
      />
    <div class="col-md-12 px-0">
      <light-box
        :items="images"
        :index="index"
        :effect="'fade'"
        @close="index = null"
      />
    </div>
    <!-- Location Detail -->
    <dx-util-popup
      :visible="popupLocationVisible"
      :show-close-button="true"
      :drag-enabled="false"
      :close-on-outside-click="false"
      :show-title="true"
      :full-screen="true"
      title="Item Location"
      @showing="popupLocationShowing"
      @hidden="popupLocationHidden"
    >
      <dx-util-position at="center" my="center" />
      <item-locations ref="itemLocationRefs" :filters="locationFilter" />
    </dx-util-popup>
    <!--Begin:: Product Details -->
    <product-details :product-id="selectedProductId"
      :show-product="isVisible"
      @close="isVisible=false,selectedProductId=null"
    />
    <!--End:: Product Details -->
    <dx-util-popup
      ref="OrderMetricsRef"
      :drag-enabled="false"
      :close-on-outside-click="true"
      :show-close-button="true"
      :show-title="true"
      width="95vw"
      height="90vh"
      title="Order Metrics"
      @hidden="onHiddenOrderMetrics"
    >
      <div>
        <order-metrics :key="rerenderKey" :asin="itemSalesMetrics.asin" :store-id="itemSalesMetrics.storeId" :product-id="itemSalesMetrics.productId" />
      </div>
    </dx-util-popup>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */

import { DxKeyboardNavigation } from 'devextreme-vue/data-grid'
import inventoryService from '@/http/requests/inventory/inventoryService'
import router from '@/router'
import GridBase from '@core/dev-extreme/mixins/grid/base'
import Filters from '@robustshell/mixins/filters'
import ExcelJS from 'exceljs'
import saveAs from 'file-saver'
import { Notify, ValueToEnum } from '@robustshell/utils/index'
import { formatDate } from '@core/utils/filter'
import {
  isValidAsin,
} from '@/views/apps/ship/validationItem'
import { v4 as uuidv4 } from 'uuid'
import { isLocation } from '@/libs/utils'
import InventorySearchFilter from '@/http/models/search-filters/InventorySearchFilter'
import settingsService from '@/http/requests/common/settings'
import SettingsTypeEnum from '@/enums/settingsTypeEnum'
import referenceTypeEnum from '@/enums/referenceTypeEnum'
import { DataGridNamesEnum } from '@/enums'
import useCurrentUser from '@/libs/app/current-user'
import FbmShipment from './view/FbmShipment.vue'
import EditQuantity from './view/EditQuantity.vue'
import ItemActions from './pages'
import ItemLocations from './view/ItemLocations.vue'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import { InventoryDataSource } from './inventoryStore'
import InventoryProductInfoCard from './components/InventoryProductInfoCard'

const defaultImageUrl = require('@/assets/images/undraw/no_images.svg')

export default {
  components: {
    'edit-quantity': EditQuantity,
    'fbm-shipment': FbmShipment,
    'item-locations': ItemLocations,
    'inventory-product-info-card': InventoryProductInfoCard,
    'dx-keyboard-navigation': DxKeyboardNavigation,
    'prep-inverntory-createoredit': () => import('./view/PrepInverntoryCreateOrEdit.vue'),
    'reconcile-with-file': () => import('./view/ReconcileWithFile.vue'),
    'reconcile-with-wfs-file': () => import('./view/ReconcileWithWFSFile.vue'),
    'move-inventory': () => import('./view/MoveInventory.vue'),
    'light-box': () => import('vue-cool-lightbox'),
    'product-details': () => import('@/views/apps/product/products/components/ProductDetails.vue'),
    'order-metrics': () => import('@/views/apps/reports/orders/OrderMetrics.vue'),
    'amazon-snapshot': () => import('./view/AmazonSnapshot.vue'),
  },
  mixins: [GridBase, Filters],
  setup() {
    const {
      userId,
      userCompanyId,
      isServingTenant,
    } = useCurrentUser()
    return {
      userId,
      userCompanyId,
      isServingTenant,
    }
  },
  data() {
    return {
      reconcileWithFileComponentId: null,
      reconcileWithWFSFileComponentId: null,
      moveInventoryComponentId: null,
      fmbShipmnetComponentId: null,
      isIndividualTenant: false,
      id: 0,
      InventoryDataSource,
      selectedRows: [],
      filters: {
        query: '',
      },
      isSearchAction: false,
      pageName: '',
      infoModal: {
        id: 0,
        title: '',
        content: {},
      },
      editPopup: {
        type: '',
        header: '',
        title: '',
        content: '',
      },
      itemAction: '',
      isAll: false,
      accountNo: '',
      itemCount: 0,
      queryParams: '',
      companyInfoParams: {
        accountNo: '',
        storeId: 0,
        warehouseId: 0,
        itemAction: '',
      },
      isFbaToolbarItemsVisibility: false,
      isWfsToolbarItemsVisibility: false,
      isShipItemVisibility: false,
      notCombined: true,
      pageAction: {},
      popupLocationVisible: false,
      locationFilter: {},
      inventoryQuantityFormData: {
        productId: 0,
        mskuFnskuPairId: 0,
        accountNo: '',
        storeId: 0,
        warehouseId: 0,
        itemAction: '',
        currentQuantity: 0,
        newQuantity: 0,
        quantity: 0,
      },
      defaultImg: defaultImageUrl,
      images: [],
      index: null,
      selectedProductId: null,
      isVisible: false,
      selectedAsin: '',
      includeAllShipped: false,
      itemSalesMetricsBlank: {
        storeId: 0,
        productId: null,
        asin: '',
      },
      itemSalesMetrics: { },
      rerenderKey: 0,
      hoveredItem: '',
      isPopoverVisible: false,
      selectedAmzAvailableData: {},
      gridStateLoaded: false,
      currentGridStateId: null,
      dataGridState: {},
    }
  },
  computed: {
    hasPermission() {
      return this.$can('read', 'resource_customer_account_no')
    },
    hasUpdateInventoryPermission() {
      return this.$can('read', 'resource_update_inventory')
    },
    hasReportPermission() {
      return this.$can('read', 'resource_reports')
    },
    dataGrid() {
      const dataGridIns = this.$refs.inventoryGridRef.instance
      return dataGridIns
    },
    dataSource() {
      const dataSource = this.dataGrid.getDataSource()
      return dataSource
    },
    quantityPopup() {
      const popup = this.$refs.quantityPopupRef.instance
      return popup
    },
    newQuantityOptions() {
      return {
        min: 0,
        max: this.editPopup.type === 'editQuantity'
          ? this.inventoryQuantityFormData.currentQuantity
          : this.inventoryQuantityFormData.currentQuantity + this.inventoryQuantityFormData.quantity,
        step: 1,
        showSpinButtons: true,
      }
    },
    showTrackInfo() {
      return this.itemAction === ItemActions.dropship.normalized || this.itemAction === ItemActions.forwarding.normalized
    },
    orderMetricsPopup() {
      return this.$refs.OrderMetricsRef.instance
    },
    quantityEditActions() {
      return [
        {
          id: 'editQuantity',
          btnId: 'btn-quantity',
          visible: true,
          name: 'Edit Quantity',
          class: 'rounded-0 p-0 text-left',
          elementAttr: {
            class: 'p-0',
          },
        },
        {
          id: 'editReserved',
          btnId: 'btn-reserved',
          visible: true,
          name: 'Edit Reserved',
          class: 'rounded-0 p-0 text-left',
          elementAttr: {
            class: 'p-0',
          },
        },
      ]
    },
  },
  watch: {
    '$route.params': {
      handler() {
        const action = ValueToEnum(ItemActions, this.$route.params.itemAction)
        this.itemAction = action.normalized
        this.setBreadcrumb(action.description)
        this.dataGrid.clearSorting()
      },
    },
    itemAction(newVal) {
      this.isFbaToolbarItemsVisibility = false
      this.isWfsToolbarItemsVisibility = false
      this.isShipItemVisibility = false
      const isPermitted = this.hasPermission || this.isIndividualTenant

      if (newVal === ItemActions.fba.normalized) {
        this.isFbaToolbarItemsVisibility = isPermitted
      }

      if (newVal === ItemActions.wfs.normalized) {
        this.isWfsToolbarItemsVisibility = isPermitted
      }

      const isShipmentAction = [
        ItemActions.fbm.normalized,
        ItemActions.storage.normalized,
        ItemActions.forwarding.normalized,
        ItemActions.b2b.normalized,
        ItemActions.dropship.normalized,
      ].some(action => action === newVal)

      if (isShipmentAction) {
        this.isShipItemVisibility = true // isPermitted
        this.notCombined = !(newVal === ItemActions.forwarding)
      }

      if (this.gridStateLoaded) {
        this.searchByFilter()
      }
      this.dataGrid.repaint()
    },
    accountNo(current) {
      if (!current) {
        this.onClearSelected()
        this.getStores()
      }
    },
    selectedStore(current, old) {
      if (current !== old) {
        this.onClearSelected()
      }
    },
  },
  created() {
    const userData = JSON.parse(localStorage.getItem('userData'))
    if (userData.tenantType === 'INDIVIDUAL') {
      this.isIndividualTenant = true
    }
    const actionRoute = router.currentRoute.params.itemAction
    const action = ValueToEnum(ItemActions, actionRoute)
    this.itemAction = action.normalized
    this.setBreadcrumb(action.description)
    this.getWarehouses()
    this.getStores()
  },
  mounted() {
    if (!InventoryDataSource.isLoaded()) {
      this.loadData(true)
    }
  },
  methods: {
    onCellPrepared(e) {
      if (e.rowType === 'data') {
        if (e.column.dataField === 'amzTotal') {
          e.cellElement.style.cssText = 'text-align: center; font-weight: bold;'
        } else if (e.column.dataField === 'amzInbound') {
          e.cellElement.style.cssText = 'color: #fc9f3c; text-align: center; font-weight: bold;'
        }
      }
    },
    onOptionChanged(e) {
      if (e.fullName === 'paging.pageSize') {
        InventoryDataSource.pageIndex(0)
      }
    },
    setBreadcrumb(description) {
      this.pageName = description
    },
    onCellDblClick(e) {
      if (e.rowType === 'data' && e.column.dataField === 'smallImage') {
        if (e.eventType === 'dxdblclick') {
          if (e.data.largeImage) {
            this.images.length = 0
            this.index = 0
            this.images.push(e.data.largeImage)
          }
        }
      }
    },
    onClickGetItemLocation(e) {
      this.popupLocationVisible = true
      const item = { ...e.row.data }
      Object.assign(this.locationFilter, {
        storeId: item.storeId,
        warehouseId: item.warehouseId,
        companyId: item.companyId,
        productId: item.productId,
        itemAction: item.itemAction,
      })
    },
    popupLocationHidden(e) {
      this.popupLocationVisible = false
    },
    popupLocationShowing(e) {
      this.$refs.itemLocationRefs.onShownItemsLocation()
    },
    // #region Download
    async onDownloadInventory(e) {
      InventorySearchFilter.itemAction = this.isAll ? 'ALL' : this.itemAction
      const filters = InventorySearchFilter.getFilters()
      const pageableQuery = 'page=0&size=10000'
      const response = await inventoryService.fetchByQuery(filters, pageableQuery)
      const data = response.data.content
      this.onDownloadExportData(data)
    },
    onDownloadSelectedInventory() {
      const data = this.dataGrid.getSelectedRowsData()
      if (Array.isArray(data) && data.length > 0) {
        this.onDownloadExportData(data)
      } else {
        Notify.warning('Please select at least one item to export data')
      }
    },
    onDownloadExportData(data) {
      const fileDate = new Date().toDateString()
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet(`Inventory ${fileDate}`)
      worksheet.columns = [
        { header: 'Title', key: 'title', width: 100 },
        { header: 'UPC', key: 'upc', width: 14 },
        { header: 'ASIN', key: 'asin', width: 14 },
        { header: 'MSKU', key: 'msku', width: 30 },
        { header: 'FNSKU', key: 'fnsku', width: 14 },
        { header: 'SerialNo', key: 'serialNo', width: 14 },
        { header: 'Expiration', key: 'expirationDate', width: 14 },
        { header: 'Store', key: 'storeName', width: 20 },
        { header: 'Action', key: 'itemAction', width: 10 },
        { header: 'Warehouse ', key: 'warehouseName', width: 18 },
        { header: 'Total QTY', key: 'quantity', width: 12 },
        { header: 'Available', key: 'quantity', width: 9 },
        { header: 'Reserved', key: 'reserved', width: 9 },
        { header: 'Shipped', key: 'sentCount', width: 9 },
      ]
      worksheet.getRow(0).font = {
        size: 16,
        bold: true,
      }
      worksheet.getRow(0).alignment = {
        vertical: 'middle',
        horizontal: 'center',
      }
      worksheet.getRow(0).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'D59C9C' },
      }
      worksheet.autoFilter = 'A1:N1'
      worksheet.views = [{ state: 'frozen', xSplit: 1, ySplit: 1 }]

      data.forEach(item => {
        worksheet.addRow([
          item.title,
          item.upc,
          item.asin,
          item.msku,
          item.fnsku,
          item.serialNo,
          formatDate(item.expirationDate),
          item.storeName,
          item.itemAction,
          item.warehouseName,
          item.quantity + item.reserved + item.sentCount,
          item.quantity,
          item.reserved,
          item.sentCount,
        ])
      })
      workbook.xlsx.writeBuffer().then(buffer => {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          `Inventory ${fileDate}.xlsx`,
        )
      })
    },
    // #endregion
    async setActionByItem(item) {
      switch (item.id) {
        case 'resetDatagridState':
          this.resetGridState()
          break
        default:
          break
      }
    },
    async resetGridState() {
      if (this.currentGridStateId === null) {
        Notify.warning('This datagrid does not have any settings to reset.')
        return
      }
      await settingsService.delete(this.currentGridStateId)
      window.location.reload()
    },
    onToolbarPreparing(e) {
      const toolbarItems = e.toolbarOptions.items
      const tempToolbarItems = [...toolbarItems]
      toolbarItems.splice(0, toolbarItems.length)

      toolbarItems.unshift({
        location: 'before',
        template: 'toolbarFilters',
      })
      toolbarItems.push({
        widget: 'dxDropDownButton',
        options: {
          keyExpr: 'id',
          displayExpr: 'name',
          text: 'Export',
          icon: 'exportxlsx',
          items: [
            {
              id: 'all',
              name: 'All Inventory',
              icon: 'selectall',
            },
            {
              id: 'selected',
              name: 'Selected',
              icon: 'exportselected',
            },
          ],
          width: 140,
          type: 'default',
          stylingMode: 'contained',
          hint: 'Download All Inventory',
          onItemClick: arg => {
            if (arg.itemData.id === 'all') {
              this.onDownloadInventory()
            } else if (arg.itemData.id === 'selected') {
              this.onDownloadSelectedInventory()
            }
          },
        },
        location: 'after',
      })
      tempToolbarItems.forEach(item => {
        toolbarItems.push(item)
      })
      toolbarItems.push({
        widget: 'dxButton',
        options: {
          hint: 'Search more on server',
          icon: 'find',
          onClick: () => {
            if (this.filters.query) {
              this.searchByFilter()
            }
          },
        },
        location: 'after',
      })
      toolbarItems.push({
        widget: 'dxCheckBox',
        cssClass: 'd-flex align-self-center',
        options: {
          text: 'ALL',
          hint: 'Select this to search in all inventory types',
          value: false,
          visible: this.hasPermission || this.isIndividualTenant,
          onValueChanged: c => {
            this.isAll = c.value
            this.searchByFilter()
          },
        },
        location: 'after',
      })
      toolbarItems.push({
        widget: 'dxButton',
        options: {
          hint: 'Upload file for reconcilation',
          icon: 'upload',
          visible: this.isFbaToolbarItemsVisibility || this.isWfsToolbarItemsVisibility || this.isIndividualTenant,
          onClick: () => {
            if (this.itemAction === ItemActions.fba.normalized) {
              this.reconcileWithFile()
            } else if (this.itemAction === ItemActions.wfs.normalized) {
              this.reconcileWithWFSFile()
            }
          },
        },
        location: 'after',
      })
      toolbarItems.push({
        widget: 'dxButton',
        options: {
          hint: 'Ship Selected Items',
          text: 'Ship',
          visible: this.isShipItemVisibility && (this.hasPermission || this.isIndividualTenant),
          onClick: () => {
            if (this.itemAction !== ItemActions.fbm.normalized
            && this.itemAction !== ItemActions.storage.normalized
            && this.itemAction !== ItemActions.forwarding.normalized
            && this.itemAction !== ItemActions.dropship.normalized
            && this.itemAction !== ItemActions.b2b.normalized) return

            this.selectedRows = this.dataGrid.getSelectedRowsData()
            if (!this.accountNo || this.accountNo === null || this.accountNo === 0) {
              Notify.danger('Please select an account.')
              return
            }
            if (this.selectedWarehouse === 0) {
              Notify.danger('Please select a Warehouse')
              return
            }
            if (this.selectedStore === 0) {
              Notify.danger('Please select a Store')
              return
            }
            if (this.selectedRows.length === 0) {
              Notify.warning('Please select at least 1 items to create a shipment')
              return
            }
            const index = this.selectedRows.findIndex(item => item.quantity === 0)
            if (index !== -1) {
              Notify.warning('Items with 0 quantity cannot be shipped.')
              return
            }
            this.fmbShipmnetComponentId = uuidv4()
          },
        },
        location: 'after',
      })
      toolbarItems.push({
        widget: 'dxButton',
        options: {
          hint: 'Move inventory',
          text: 'Move',
          color: 'default',
          visible: this.hasPermission || this.isIndividualTenant,
          onClick: () => {
            this.selectedRows = this.dataGrid.getSelectedRowsData()
            this.moveInventory()
          },
        },
        location: 'after',
      })
      toolbarItems.push({
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          onClick: () => {
            this.loadData(false)
          },
        },
        location: 'after',
      })
      toolbarItems.push({
        widget: 'dxDropDownButton',
        options: {
          keyExpr: 'id',
          displayExpr: 'name',
          icon: 'menu',
          showArrowIcon: false,
          items: [
            {
              id: 'resetDatagridState',
              name: 'Reset Datagrid Settings',
              icon: 'icon bi-x-square',
            },
          ],
          width: 36,
          type: 'default',
          stylingMode: 'contained',
          hint: 'Set Preferences',
          dropDownOptions: { width: 'auto' },
          dropDownContentTemplate: 'buttons',
        },
        location: 'after',
      })
    },
    // #region Datagrid data load
    loadData(initial) {
      InventorySearchFilter.setDefaultFilters()
      if (initial) {
        InventorySearchFilter.itemAction = this.isAll ? 'ALL' : this.itemAction
        const filters = InventorySearchFilter.getFilters()
        InventoryDataSource.searchValue(filters)
      } else {
        if (this.hasPermission) this.accountNo = ''
        InventorySearchFilter.accountNo = this.accountNo
        InventorySearchFilter.itemAction = this.itemAction
        this.dataGrid.clearFilter()
        const filters = InventorySearchFilter.getFilters()
        InventoryDataSource.searchValue(filters)
        InventoryDataSource.reload()
        this.selectedWarehouse = InventorySearchFilter.warehouseId
        this.filters.query = InventorySearchFilter.q
        this.itemAction = this.isAll ? 'ALL' : this.itemAction
      }
    },
    searchByFilter() {
      InventorySearchFilter.accountNo = this.accountNo
      InventorySearchFilter.storeId = this.selectedStore
      InventorySearchFilter.warehouseId = this.selectedWarehouse
      InventorySearchFilter.itemAction = this.isAll ? 'ALL' : this.itemAction
      InventorySearchFilter.includeAllShipped = this.includeAllShipped
      InventorySearchFilter.location = ''
      InventorySearchFilter.q = ''
      if (isLocation(this.filters.query)) {
        InventorySearchFilter.location = this.filters.query
      } else {
        InventorySearchFilter.q = this.filters.query
      }

      const filters = InventorySearchFilter.getFilters()
      InventoryDataSource.searchValue(filters)
      InventoryDataSource.reload()
    },
    onFilterChangeAllShipped(e) {
      this.searchByFilter()
    },
    onFilterByAccountNoLink(e, row) {
      this.accountNo = row.data.accountNo.toString()
      this.getStores(this.accountNo)
      this.searchByFilter()
    },
    onFilterByStoreLink(e, row) {
      this.accountNo = row.data.accountNo.toString()
      this.selectedStore = row.data.storeId.toString()
      this.getStores(this.accountNo)
      this.searchByFilter()
    },
    onFilterByAccountNo(e) {
      if (e.event && e.event.keyCode === 13) {
        if (e.event.target.value !== '' && e.event.target.value !== null) {
          this.accountNo = e.event.target.value.toString()
          this.getStores(this.accountNo)
          this.searchByFilter()
        }
      } else if ((!e.value && e.event && e.event.type && e.event.type !== 'keydown') || (e.event && e.event.keyCode === 27)) {
        this.accountNo = ''
        this.selectedStore = 0
        this.searchByFilter()
      }
    },
    onReloadPage() {
      this.loadData(false)
    },
    onRowChanged() {
      this.searchByFilter()
      this.selectedRows = []
      this.dataGrid.clearSelection()
    },
    // #endregion
    onClearSelected(payload) {
      this.dataGrid.clearSelection()
      this.searchByFilter()
    },
    onEditorPreparing(e) {
      if (e.parentType === 'searchPanel') {
        e.editorOptions.onValueChanged = arg => {
          const query = arg.component.option('value')
          if (!query) {
            this.filters.query = query.trim()
            e.component.searchByText('')
            this.searchByFilter()
          }
        }
        e.editorOptions.onKeyDown = arg => {
          if (arg.event.keyCode === 13) {
            let query = arg.component.option('value')
            query = query.trim()
            arg.component.option('value', query)
            this.filters.query = query
            this.searchByFilter()
            e.component.searchByText(query)
          }
          if (arg.event.keyCode === 27) {
            e.component.searchByText('')
            arg.component.option('value', '')
            this.filters.query = ''
            this.searchByFilter()
          }
        }
      }
    },
    onEditQuantityAndReservedClink(e, row, id) {
      if (!id) return
      const item = { ...row.data }
      this.inventoryQuantityFormData = {
        productId: item.productId,
        mskuFnskuPairId: item.mskuFnskuPairId,
        id: item.id,
        accountNo: this.accountNo,
        companyId: item.companyId,
        storeId: item.storeId,
        warehouseId: item.warehouseId,
        itemAction: this.itemAction,
      }
      switch (id) {
        case 'editQuantity':
          this.editPopup.type = 'editQuantity'
          this.editPopup.header = 'Quantity'
          this.editPopup.title = 'Please use this edit only for decrementing item quantity.'
          this.editPopup.content = 'This change will increase the item quantity in inbound (Receive Batch) while decreasing the item quantity in this inventory table.'
          this.inventoryQuantityFormData.currentQuantity = item.quantity
          this.inventoryQuantityFormData.newQuantity = item.quantity
          this.inventoryQuantityFormData.quantity = item.quantity
          break
        case 'editReserved':
          this.editPopup.type = 'editReserved'
          this.editPopup.header = 'Reserved'
          this.editPopup.title = 'Increment or decrement reserved count.'
          this.editPopup.content = 'Please use this form to incermenet or decrement reserved count. You can increment reserved count up to available quantity.'
          this.inventoryQuantityFormData.currentQuantity = item.reserved
          this.inventoryQuantityFormData.newQuantity = item.reserved
          this.inventoryQuantityFormData.quantity = item.quantity
          break
        default:
          break
      }
      this.quantityPopup.show()
    },
    onSubmitUpdate() {
      if (this.editPopup.type === 'editQuantity') {
        this.onSubmitQuantityUpdate()
      } else {
        this.onSubmitReservedUpdate()
      }
    },
    onSubmitQuantityUpdate() {
      const form = this.$refs.inventoryQuantityFormRef.instance
      const formValidate = form.validate()
      if (formValidate.isValid) {
        if (this.inventoryQuantityFormData.currentQuantity !== this.inventoryQuantityFormData.newQuantity) {
          inventoryService.decrementItemQuantity(this.inventoryQuantityFormData).then(() => {
            this.onRowChanged()
            this.onCancelQuantityUpdate()
          })
        } else {
          Notify.info('No change has been detected.')
        }
      }
    },
    onSubmitReservedUpdate() {
      const form = this.$refs.inventoryQuantityFormRef.instance
      const formValidate = form.validate()
      if (formValidate.isValid) {
        if (this.inventoryQuantityFormData.currentQuantity !== this.inventoryQuantityFormData.newQuantity) {
          inventoryService.updateReserveItemsByProductId(this.inventoryQuantityFormData).then(() => {
            this.onRowChanged()
            this.onCancelQuantityUpdate()
          })
        } else {
          Notify.info('No change has been detected.')
        }
      }
    },
    onCancelQuantityUpdate() {
      const form = this.$refs.inventoryQuantityFormRef.instance
      form.resetValues()
      this.quantityPopup.hide()
    },
    info(data) {
      this.infoModal.title = data.value
      this.infoModal.content = Object.fromEntries(Object.entries(data.data).sort())
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = {}
    },
    moveInventory() {
      if (!this.isIndividualTenant && (!this.accountNo || this.accountNo === '' || this.accountNo === 0)) {
        Notify.warning('Please enter an account no')
        return
      }
      if (this.selectedWarehouse === 0) {
        Notify.warning('Please select a Warehouse')
        return
      }
      if (this.selectedStore === 0) {
        Notify.warning('Please select a Store')
        return
      }
      if (this.selectedRows.length === 0) {
        Notify.warning('Please select at least one item to move')
        return
      }
      this.companyInfoParams.accountNo = this.accountNo
      this.companyInfoParams.storeId = this.selectedStore
      this.companyInfoParams.warehouseId = this.selectedWarehouse
      this.companyInfoParams.itemAction = this.itemAction
      this.moveInventoryComponentId = uuidv4()
    },
    reconcileWithFile() {
      if (!this.isIndividualTenant && (!this.accountNo || this.accountNo === '' || this.accountNo === 0)) {
        Notify.warning('Please enter an account no')
        return
      }
      if (this.selectedWarehouse === 0) {
        Notify.warning('Please select a Warehouse')
        return
      }
      if (this.selectedStore === 0) {
        Notify.warning('Please select a Store')
        return
      }
      this.companyInfoParams.accountNo = this.accountNo
      this.companyInfoParams.storeId = this.selectedStore
      this.companyInfoParams.warehouseId = this.selectedWarehouse
      this.companyInfoParams.itemAction = this.itemAction
      this.reconcileWithFileComponentId = uuidv4()
    },
    reconcileWithWFSFile() {
      if (!this.isIndividualTenant && (!this.accountNo || this.accountNo === '' || this.accountNo === 0)) {
        Notify.warning('Please enter an account no')
        return
      }
      if (this.selectedWarehouse === 0) {
        Notify.warning('Please select a Warehouse')
        return
      }
      if (this.selectedStore === 0) {
        Notify.warning('Please select a Store')
        return
      }
      this.companyInfoParams = {
        accountNo: this.accountNo,
        storeId: this.selectedStore,
        warehouseId: this.selectedWarehouse,
        itemAction: this.itemAction,
      }

      this.reconcileWithWFSFileComponentId = uuidv4()
    },
    openProductDetails(productId) {
      this.selectedProductId = productId
      this.isVisible = true
    },
    onOpenOrderMetrics(e) {
      const item = { ...e.row.data }
      if (!isValidAsin(item.asin)) {
        Notify.danger('Seleceted item does not have a valid asin')
        return
      }
      Object.assign(this.itemSalesMetrics, {
        storeId: item.storeId,
        productId: item.productId,
        asin: item.asin,
      })
      this.rerenderKey += 1
      this.orderMetricsPopup.show()
    },
    onHiddenOrderMetrics() {
      this.itemSalesMetrics = this.itemSalesMetricsBlank
    },
    togglePopover(data) {
      const rowId = data.data.id
      const amzInventory = JSON.parse(data.data.amzInventory)
      this.hoveredItem = `#amz-${rowId}`
      this.selectedAmzAvailableData = amzInventory
      this.isPopoverVisible = true
    },
    closePopover() {
      this.isPopoverVisible = false
    },
    async loadDataGridState(e) {
      const dataGridKey = DataGridNamesEnum.INVENTORY_GRID.key
      const result = await settingsService.getByReference(referenceTypeEnum.USER.value, this.userId, dataGridKey)
      this.currentGridStateId = result.data.id
      this.dataGridState = {}
      if (result.data.value) {
        const state = JSON.parse(result.data.value)
        state.searchText = ''
        this.dataGridState = state
      }
      this.gridStateLoaded = true
      return this.dataGridState
    },
    async saveDataGridState(e) {
      if (e === undefined || e === null) {
        return
      }

      const dataGridPreferences = { ...e }

      dataGridPreferences.selectedRowKeys = []
      dataGridPreferences.searchText = ''
      if (JSON.stringify(dataGridPreferences) === JSON.stringify(this.dataGridState)) {
        return
      }

      const dataGridKey = DataGridNamesEnum.INVENTORY_GRID.key
      const preferenceData = {
        companyId: this.userCompanyId,
        referenceId: this.userId,
        referenceType: referenceTypeEnum.USER.value,
        settingType: SettingsTypeEnum.DATAGRID_STATES.value,
        settingKey: dataGridKey,
        description: `State of the ${dataGridKey} for UserId: ${this.userId}`,
        settingName: dataGridKey,
        value: JSON.stringify(dataGridPreferences),
      }
      const result = await settingsService.createOrUpdate(preferenceData)
      this.currentGridStateId = result.data.body.id
    },
  },
}
</script>

<style lang="scss">
#quantityEditActions  {
  .dx-icon {
    font-size: 1.143rem;
    color: #FFFFFF;
    margin-right: 0.5rem;
  }
  .dx-button-content {
    text-align: left;
    background-color: #e8a03c;
  }
  .dx-buttongroup-item {
    padding-left: 5px;
    padding-right: 1px;
  }
  .dx-dropdownbutton-action:not(.dx-buttongroup-last-item).dx-button {
    border-right-width: 0;
  }
}
</style>
